import media from 'styled-media-query';
import styled, { css } from 'styled-components';

export const Container = styled.section`
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 100%;
  max-width: 1140px;
`;

export const ContentContri = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 30px;

  & + div {
    padding-top: 30px;
    border-top: 1px solid rgba(0,0,0,0.2);
  }

  img {
    width: 273px;
    margin-right: 30px;
    border-radius: 3px;
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: -20px;

    strong {
      font-size: 18px;
      font-weight: 700;
      color: #333;
      margin: 20px 0 2px;
      text-transform: uppercase;
    }

    p {
      text-indent: 20px;
      color: #555;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      margin: 0;
    }
  }

  ${media.lessThan('large')`
    flex-direction: column;
    padding: 0 10px;
    margin-bottom: 20px;
    & + div {
      padding-top: 20px;
    }
    img {
      width: 100px;
      margin-right: 12px;
    }
    > div {
      margin-top: -10px;
      strong {
        font-size: 14px;
        margin: 14px 0 0;
      }
      p {
        text-indent: 10px;
        font-size: 12px;
        line-height: 16px;
      }
    }
  `};
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px auto 0;
  ${media.lessThan('large')`
    margin-top: 10px;
  `};
`;

export const ButtonContri = styled.a`
  border-radius: 4px;
  padding: 6px 0;
  width: 220px;
  text-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  background: transparent;

  a + & {
    margin-left: 20px;
  }

  ${media.lessThan('large')`
    font-size: 13px;
    width: 160px;
  `};


  ${(props) => props.typeBtn === 'baixar' && css`
    color: #ff0000;
    border: 1px solid #ff0000;
    svg { color: #ff0000 };
    &:hover { background: #ff0000 };
  `};

  ${(props) => props.typeBtn === 'abrir' && css`
    color: #246EB9;
    border: 1px solid #246EB9;
    svg { color: #246EB9 };
    &:hover { background: #246EB9 };
  `};

  &:hover {
    color: #FFF;
    svg { color: #FFF };
  }

  svg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
`;
