import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { FaUserGraduate, FaFileAlt, FaFileDownload } from 'react-icons/fa'

import SEO from '~/components/SEO'
import Title from '~/components/Title'
import Layout from '~/components/Layout'

import { Container, ContentContri, ButtonContri, Buttons } from '~/styles/pages/contribuicoes-academicas'

const QUERY_IMAGES = graphql`
{
  allFile(filter: {relativePath: {regex: "/contribuicoesAcademicas/"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 273, quality: 100) {
          src
        }
      }
    }
  }
}`

const ContriPage = () => {
  const { allFile: { nodes: images } } = useStaticQuery(QUERY_IMAGES)
  const docs = [
    {
      id: 6,
      title: 'Revista dos Tribunais',
      author: 'Edilson Jair Casagrande, Juarez Casagrande e Sarah Viana Veloso.',
      image: images[1].childImageSharp.fluid.src,
      name: '100-anos-revista-dos-tribunais',
      abstract: [
        'O presente artigo se dedica a análise dos direito do produtor exportador ao crédio presumido do IPI quando da realização de operações de exportação indireta. De acordo com as disposições da Lei 9.363/1996...'
      ]
    },
    {
      id: 1,
      title: 'Revista de estudos tributários - O direito ao crédito do IPI acumulado e a Lei nº9.779 99.',
      author: 'Edilson Jair Casagrande.',
      image: images[3].childImageSharp.fluid.src,
      name: 'o-direito-ao-credito-do-IPI-acumulado',
      abstract: [
        'Assunto palpitante e que deve tomar os forros de nossa Justiça Federal é aquele que diz respeito ao direito do contribuinte a manter o crédito do IPI e a promover a respectiva utilização, ainda que o mesmo verse sobre período anterior à edição da Lei nº 9.779/99.',
        'Pretendemos desenvolver o raciocínio que segue, com o objetivo de provocar o amadurecimento sobre o tema.'
      ]
    },
    {
      id: 2,
      title: 'O sentido e alcance da imunidade constitucional para templos de qualquer culto.',
      author: 'Edilson Jair Casagrande, Valdinei Ramos Gandra.',
      image: images[0].childImageSharp.fluid.src,
      name: 'o-sentido-e-alcance-da-imunidade-constitucional-para-templos-de-qualquer-culto',
      abstract: [
        'O presente artigo, com cunho expositivo e explicativo, tem por escopo debater o sentido e o alcance da imunidade tributária dos templos de qualquer culto estabelecida pelo art. 150, VI, b da Constituição Federal de 1988, bem como o efeito prático destas em relação às entidades abrangidas, tal qual as igrejas. A pesquisa tem como fontes a doutrina do âmbito jurídico, sobretudo no ramo do direito tributário e busca subsídios também na Jurisprudência, com destaque para o Supremo Tribunal Federal, apresentando, inclusive, tópico crítico em relação à atual posição de nossa Corte Suprema sobre a abrangência de tal imunidade, com explicitação, ao fim, dos principais reflexos da imunização quanto  à tributação dos imunizados.'
      ]
    },
    {
      id: 3,
      title: 'Revista de estudos tributários - Da impossibilidade de protesto cartorário de CDA de origem tributária',
      author: 'Edilson Jair Casagrande.',
      image: images[2].childImageSharp.fluid.src,
      name: 'revista-estudos-tributarios-da-impossibilidade-de-protesto-cartorário-de-CDA-de-origem-tributári',
      abstract: [
        'Tem-se tornado comum que Municípios levem a protesto cartorário certidões de dívida ativa representativa de obrigações tributárias. Conteúdo, respeitadas as opiniões divergentes, não nos parece legal nem razoável referido expediente.',
        'Objetivando provocar o amadurecimento do debate sobre referida questão, submeto à apreciação dos leitores as seguintes considerações.'
      ]
    },
    {
      id: 4,
      title: 'Temas de Direito Tributário - O chamado crédito presumido de IPI (PIS E COFINS)',
      author: 'Edilson Jair Casagrande.',
      image: images[5].childImageSharp.fluid.src,
      name: 'temas-de-direito-tributario',
      abstract: [
        'Do conceito de receita de exportação e do direito ao crédito dos produtores exportadores ainda que os produtos exportados não sejam onerados pelo IPI na saída.'
      ]
    },
    {
      id: 5,
      title: 'Revista de estudos tributários - A substituição Tributária previdenciária',
      author: 'Edilson Jair Casagrande.',
      image: images[6].childImageSharp.fluid.src,
      name: 'a-substituicao-tributaria-previdenciaria',
      abstract: [
        'Sem pretendermos exaurir o assunto mas tão-só buscando colaborar com novos argumentos, debruçamo nos sobre a redação do art. 23 da Lei nº 9.711 de 21/11/1998, que, dando nova redação ao art. 31, da Lei nº 8.212/97, instituiu o que se denominou “substituição tributária da contribuição previdenciária”, na alíquota de 11% sobre o valor bruto da nota fiscal ou fatura de prestação de serviços(faturamento decorrente da prestação de serviços).'
      ]
    },
    {
      id: 0,
      title: 'Revista dialética de direito tributário - Da impossibilidade de protesto cartorário de CDA de origem tributária.',
      author: 'Edilson Jair Casagrande.',
      image: images[2].childImageSharp.fluid.src,
      name: 'revista-dialetica-da-impossibilidade-de-protesto-cartorário-de-CDA-de-origem-tributária',
      abstract: [
        'Tem-se tornado comum que Municípios levem a protesto cartorário certidões de dívida ativa representativa de obrigações tributárias. Conteúdo, respeitadas as opiniões divergentes, não nos parece legal nem razoável referido expediente.',
        'Objetivando provocar o amadurecimento do debate sobre referida questão, submeto à apreciação dos leitores as seguintes considerações.'
      ]
    },
    {
      id: 0,
      title: 'Revista de Direito Tributário Comtemporâneo',
      author: 'Edilson Jair Casagrande e Juarez Casagrande',
      image: images[7].childImageSharp.fluid.src,
      name: 'direito-tributario-contemporaneo',
      abstract: [
        'O presente artigo busca demonstrar que as Leis 10.637/02 e 10.833/03 além de possibilitarem a tomada de crédito de bens e serviços utilizados como insumos na prestação de serviços e na produção ou fabricação de bens ou produtos destinados à venda, também trouxeram a possibilidade da tomada de crédito relativos a custos e despesas, sem vincular a tomada dos créditos à utilização na prestação de serviços e na produção ou fabricação de bens ou produtos destinados à venda, já que tal creditamento decorre do próprio princípio da não cumulatividade inserido pelo artigo 195, da CRFB, que elegeu a atividade e não o produto para que fosse possível a tomada de crédito.'
      ]
    }
  ]

  return (
    <Layout>
      <SEO title="Contribuições Acadêmicas" description="Acessa essa aba para baixar ou ler nossas contribuições acadêmicas" />
      <Title content="Contribuições Acadêmicas" icon={<FaUserGraduate />} />
      <Container>
        {docs.map((doc) => (
          <ContentContri key={doc.id}>
            <img src={doc.image} alt={doc.title} />
            <div>
              <strong>Título</strong>
              <p>{doc.title}</p>
              <strong>Autor</strong>
              <p>{doc.author}</p>
              <strong>Resumo</strong>
              {doc.abstract.map((textAbstract) => <p key={textAbstract.length}>{textAbstract}</p>)}
              <Buttons>
                <ButtonContri typeBtn="abrir" href={`pdf.php?name=${doc.name}`}>
                  <FaFileAlt /> Abrir Documento
                </ButtonContri>
                <ButtonContri typeBtn="baixar" href={`./documents/${doc.name}.pdf?download=true`} download={`${doc.name}.pdf`} target="_parent">
                  <FaFileDownload /> Baixar Documento
                </ButtonContri>
              </Buttons>
            </div>
          </ContentContri>
        ))}
      </Container>
    </Layout>
  )
}
export default ContriPage
